import {html, LitElement, css} from "lit";
import {customElement} from 'lit/decorators.js';

@customElement("my-element")
export class Index extends LitElement {
  static styles = css`button {
    width: 200px;
  }`;

  render() {
    return html`
        <h1>Hello from LitElement!</h1>
        <button @click="${this.clickHandler}">Click Me</button>
    `;
  }

  clickHandler() {
    alert("clicked");
  }
}